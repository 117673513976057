<template>
  <div class="container">
    <div class="ewm page-sub-box">
      <div class="locker_setting_list sub_new_style01 sub_ui_box1">
        <div class="page_search_box line_bottom_1px">
          <DxValidationGroup ref="searchValidationGroup">
            <div class="flex space-x-2 inner">
              <div class="mt-2">기준년</div>
              <DxDateBox
                :edit-enabled="false"
                :styling-mode="stylingMode"
                width="100"
                height="30"
                v-model="searchType.customTypes.startDt"
                type="date"
                display-format="yyyy"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :max="searchType.customTypes.endDt"
                maxLength="4"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'decade',
                  minZoomLevel: 'decade',
                  maxZoomLevel: 'decade',
                }"
                @value-changed="onDayStartChanged"
              >
                <DxValidator>
                  <DxRequiredRule message="기준년은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <div class="mt-1">~</div>
              <DxDateBox
                :styling-mode="stylingMode"
                width="100"
                height="30"
                v-model="searchType.customTypes.endDt"
                type="date"
                display-format="yyyy"
                dateSerializationFormat="yyyyMMdd"
                dateOutOfRangeMessage="종료일은 시작일보다 크거나 같아야 합니다."
                :min="searchType.customTypes.startDt"
                maxLength="10"
                invalid-date-message="입력 데이터가 유효하지 않습니다."
                :calendar-options="{
                  zoomLevel: 'decade',
                  minZoomLevel: 'decade',
                  maxZoomLevel: 'decade',
                }"
                @value-changed="onDayEndChanged"
              >
                <DxValidator>
                  <DxRequiredRule message="기준년은 필수입니다." />
                </DxValidator>
              </DxDateBox>
              <DxButton text="검색" class="btn_M box-btn-search" type="button" :height="30" @click="selectDataList" />
            </div>
          </DxValidationGroup>
        </div>
      </div>
      <esp-dx-data-grid :data-grid="dataGrid" ref="resignationGrid" />
    </div>
  </div>
</template>

<script>
  import { DxButton } from 'devextreme-vue/button';
  import { DxDateBox } from 'devextreme-vue/date-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import { DxValidationGroup } from 'devextreme-vue/validation-group';
  import { getPastFromToday, isSuccess } from '@/plugins/common-lib';
  import CustomStore from 'devextreme/data/custom_store';
  import EspDxDataGrid from '@/components/devextreme/esp-dx-data-grid.vue';

  let vm = this;

  export default {
    components: {
      EspDxDataGrid,
      DxButton,
      DxDateBox,
      DxRequiredRule,
      DxValidator,
      DxValidationGroup,
    },
    data() {
      return {
        stylingMode: 'outlined', //outlined, underlined, filled
        searchType: {
          obj: {},
          defaultObj: { id: 'ROOT', codeValue: 'ROOT', codeNm: '전체' },
          defaultValue: 'ROOT',
          customTypes: {
            startDt: getPastFromToday(1, 'years'),
            endDt: getPastFromToday(0, 'years'),
          },
          paramsData: {},
        },
        dataGrid: {
          keyExpr: 'id',
          refName: 'resignationGrid',
          allowColumnResizing: true, //컬럼 사이즈 허용
          columnResizingMode: 'widget',
          columnAutoWidth: true,
          showBorders: false, //border 유무
          showColumnHeaders: true, //컬럼 헤더 유무
          showColumnLines: false, //컬럼 세로선 유무
          showRowLines: true, //컬럼 가로선 유무
          rowAlternationEnabled: false,
          dataSource: [],
          // width:'200',     // 주석처리시 100%
          height: 'calc(100vh - 370px)', // 주석처리시 100%
          scrolling: {
            mode: 'standard',
          },
          callApi: 'CALL_EWM_API',
          apiActionNm: {},
          customEvent: {},
          showActionButtons: {
            customButtons: [],
          },
          grouping: {
            contextMenuEnabled: false,
            autoExpandAll: false,
            allowCollapsing: true,
            expandMode: 'rowClick', // rowClick or buttonClick
          },
          groupPanel: {
            visible: false,
          },
          columnChooser: {
            enabled: false, // 컬럼 Chooser 버튼 사용유무
          },
          loadPanel: {
            enabled: true, // 로딩바 표시 유무
          },
          sorting: {
            mode: 'multiple', // single multiple
          },
          remoteOperations: {
            // 서버사이드 여부
            filtering: false,
            sorting: false,
            grouping: false,
            paging: false,
          },
          paging: {
            enabled: false,
            pageSize: 10,
            pageIndex: 0, // 시작페이지
          },
          pager: {
            visible: false, //페이저 표시 여부
            showPageSizeSelector: false, //페이지 사이즈 선택버튼 표시 여부
            allowedPageSizes: [],
            displayMode: 'compact', //표시 모드 : ['full', 'compact']
            showInfo: true, //페이지 정보 표시 여부 : full인 경우만 사용 가능
            showNavigationButtons: true, //페이지 네비게이션(화살표) 버튼 표시 여부 : full인 경우만 사용 가능
          },
          filterRow: {
            visible: true,
            operationDescriptions: {
              contains: '포함',
            },
          },
          headerFilter: {
            visible: true,
          },
          editing: {
            allowUpdating: false, // 저장, 취소 버튼을 없애고 싶으면 allowUpdating allowAdding 를 둘다 false 설정
            allowAdding: false, // 추가 버튼을 없애고 싶으면 false설정
            allowDeleting: false,
            mode: 'batch', //수정 모드: ['row', 'cell', 'batch']
            startEditAction: 'click', //셀 편집 상태로 변경 할 이벤트 타입 : ['click', 'dbclick'] / 'cell', 'batch' 모드인 경우에만 가능
            selectTextOnEditStart: false, //셀 수정시 텍스트 전체 선택 여부
          },
          selecting: {
            mode: 'single', //행 단일/멀티 선택 타입 : ['single', 'multiple']
            selectAllMode: 'allPages', //행 선택 허용 범위 : ['allPages', 'page']
            showCheckBoxesMode: 'always', //행 선택 모드 : ['none', 'onClick', 'onLongTap', 'always']
          },
          columns: [
            {
              caption: 'No',
              dataField: 'id',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowFiltering: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '기준년월',
              dataField: 'baseDt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: true,
              calculateCellValue: rowData => {
                return this.$_commonlib.formatDate(rowData.baseDt, 'YYYYMM', 'YYYY.MM');
              },
            },
            {
              caption: '전월상담사 수',
              dataField: 'lastMonthCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '당월상담사 수',
              dataField: 'thisMonthCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              caption: '신규입사자',
              dataField: 'newJoinCnt',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
            },
            {
              multiHeaderNm: '근속기간',
              columns: [
                {
                  caption: '3개월 미만',
                  dataField: 'under3mCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                },
                {
                  caption: '6개월 미만',
                  dataField: 'under6mCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                },
                {
                  caption: '1년 미만',
                  dataField: 'under1yCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                },
                {
                  caption: '3년 미만',
                  dataField: 'under3yCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                },
                {
                  caption: '5년 미만',
                  dataField: 'under5yCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                },
                {
                  caption: '10년 미만',
                  dataField: 'under10yCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                },
                {
                  caption: '10년 이상',
                  dataField: 'over10yCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                },
                {
                  caption: '합',
                  dataField: 'totalCnt',
                  alignment: 'center',
                  visible: true,
                  allowEditing: false,
                  allowHeaderFiltering: false,
                },
              ],
            },
            {
              caption: '퇴사율',
              dataField: 'retireRate',
              alignment: 'center',
              visible: true,
              allowEditing: false,
              allowHeaderFiltering: false,
              calculateDisplayCellValue: rowData => {
                return `${rowData.retireRate}%`;
              },
            },
          ],
        },
      };
    },
    computed: {
      searchValidationGroup: function () {
        return this.$refs['searchValidationGroup'].instance;
      },
    },
    methods: {
      /** @description : 데이터 검색 메서드 */
      async selectDataList() {
        const start = vm.searchType.customTypes.startDt.slice(0, 4);
        const end = vm.searchType.customTypes.endDt.slice(0, 4);
        if (start > end) {
          return;
        }

        if (!vm.searchValidationGroup.validate().isValid) {
          return;
        }

        this.dataGrid.dataSource = new CustomStore({
          async load() {
            const payload = {
              actionname: 'EWM_HR_RESIGN_SEARCH',
              data: {
                startDt: start,
                endDt: end,
              },
              loading: false,
            };

            const res = await vm.CALL_EWM_API(payload);

            if (isSuccess(res)) {
              res.data.data.forEach((d, index) => {
                d.id = index + 1;
              });

              const rtnData = {
                data: res.data.data,
                totalCount: res.data.header.totalCount,
              };

              vm.$refs.resignationGrid.totalCount = rtnData.totalCount;
              return rtnData;
            }
          },
        });
      },
      onDayStartChanged(e) {
        const changed = e.value.slice(0, 4) + '0101';
        this.searchType.customTypes.startDt = changed;
      },
      onDayEndChanged(e) {
        const changed = e.value.slice(0, 4) + '1231';
        this.searchType.customTypes.endDt = changed;
      },
      /** @description : 라이프사이클 created시 호출되는 메서드 */
      createdData() {
        vm = this;
      },
      /** @description : 라이프사이클 mounted시 호출되는 메서드 */
      mountData() {
        this.selectDataList();
      },
    },
    created() {
      this.createdData();
    },
    mounted() {
      this.mountData();
    },
  };
</script>
